import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import { message } from "antd";

export const getTalentOnboardingQuestions = createAsyncThunk(
  "talent/getTalentOnboardingQuestions",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/talent/onboarding/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Response Data:", response.data); // Debugging statement
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const postTalentOnboardingQuestions = createAsyncThunk(
  "talent/postTalentOnboardingQuestions",
  async (data: any, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(`${api}/talent/onboarding/`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Response Data:", response.data); // Debugging statement
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTalentDashboard = createAsyncThunk(
  "talent/getTalentDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/talent/dashboard/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Response Data:", response.data); // Debugging statement
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTalentDashboardRecommended = createAsyncThunk(
  "talent/getTalentDashboardRecommended",
  async (recommendationType: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      // await Promise.all([promiseOne(), promiseTwo()])
      const response = await axios.get(
        `${api}/recommend/talent/${recommendationType}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      console.log("Response Data:", response.data); // Debugging statement
      return { [recommendationType]: response.data };
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue({
        [recommendationType]: [],
        ...err.response.data,
      });
    }
  },
);

export const getTalentExplore = createAsyncThunk(
  "talent/getTalentExplore",
  async (query: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      // await Promise.all([promiseOne(), promiseTwo()])
      const response = await axios.get(
        `${api}/recommend/explore/?q=${query || "a"}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);
export const getTalentSavedItems = createAsyncThunk(
  "talent/getTalentSavedItems",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      // await Promise.all([promiseOne(), promiseTwo()])
      //console.log("Sending Data:", JSON.stringify(data, null, 2)); // Debugging statement
      const response = await axios.get(`${api}/talent/saved_data/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Response Data:", response.data); // Debugging statement
      return response.data;
    } catch (err: any) {
      console.error("Error in API call:", err); // Debugging statement
      if (!err.response) {
        throw err;
      }
      
      console.error("Error Response Data:", err.response.data); // Debugging statement
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTalentPublicProfile = createAsyncThunk(
  "talent/getTalentPublicProfile",
  async (id: string | undefined, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/talent/profile/public?talent=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getTalentProfilePic = createAsyncThunk(
  "talent/getTalentProfilePic",
  async (id: string | null, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(
        `${api}/talent/profile_pic/?talent_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTalentProfile = createAsyncThunk(
  "talent/updateTalentProfile",
  async (profilePayload: any, { rejectWithValue, dispatch }) => {
    const { userId } = profilePayload;
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(
        `${api}/talent/profile/update/`,
        profilePayload.payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        },
      );
      // return response.data;
      message.success("Profile updated successfully");
      dispatch(getTalentPublicProfile(userId));
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      message.error("Profile update failed");
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTalentWorkLocations = createAsyncThunk(
  "talent/getTalentWorkLocations",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/shared/work-locations/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  value: 0,
  dashboard: null,
  profileInfo: null,
  dashboardRecommended: null,
  dashboardCoaches: null,
  dashboardExplore: null,
  dashboardSavedItems: null,
};

export const talentSlice = createSlice({
  name: "talent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getTalentOnboardingQuestions.fulfilled,
        (state: any, action: any) => {
          // no action needed here as we're using data into local State
        },
      )
      .addCase(
        getTalentOnboardingQuestions.rejected,
        (state: any, action: any) => {
          return action.payload;
        },
      );
    builder
      .addCase(
        postTalentOnboardingQuestions.fulfilled,
        (state: any, action: any) => {
          return action.payload;
        },
      )
      .addCase(
        postTalentOnboardingQuestions.rejected,
        (state: any, action: any) => {
          return action.payload;
        },
      );
    builder
      .addCase(getTalentDashboard.fulfilled, (state: any, action: any) => {
        state.dashboard = action.payload;
      })
      .addCase(getTalentDashboard.rejected, (state: any, action: any) => {
        return action.payload;
      });
    builder
      .addCase(getTalentPublicProfile.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.profileInfo = { ...(state.profileInfo ?? {}), ...payload };
      })
      .addCase(getTalentPublicProfile.rejected, (state: any, action: any) => {
        return action.payload;
      });
    builder
      .addCase(getTalentProfilePic.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.profileInfo = { ...(state.profileInfo ?? {}), ...payload };
      })
      .addCase(getTalentProfilePic.rejected, (state: any, action: any) => {
        state.profileInfo = {
          ...(state.profileInfo ?? {}),
          profilePicError: true,
        };
      });
    builder
      .addCase(
        getTalentDashboardRecommended.fulfilled,
        (state: any, action: any) => {
          state.dashboardRecommended = {
            ...state.dashboardRecommended,
            ...action.payload,
          };
        },
      )
      .addCase(
        getTalentDashboardRecommended.rejected,
        (state: any, action: any) => {
          // This is done to populate empty array incase any api failes (with 404 or something)
          // We can revamp our slicws to also have an error state incase of api Failure. Right now we can't differentiate between
          // initial state or error state

          state.dashboardRecommended = {
            ...state.dashboardRecommended,
            ...action.payload,
          };
        },
      );
    builder
      .addCase(getTalentExplore.fulfilled, (state: any, action: any) => {
        state.dashboardExplore = action.payload;
      })
      .addCase(getTalentExplore.rejected, (state: any, action: any) => {
        return action.payload;
      });

    builder
      .addCase(getTalentSavedItems.fulfilled, (state: any, action: any) => {
        state.dashboardSavedItems = action.payload;
      })
      .addCase(getTalentSavedItems.rejected, (state: any, action: any) => {
        return action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = talentSlice.actions;

export default talentSlice.reducer;
